import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

export const SiteContext = React.createContext()

export const SiteStore = ({ children, globalData, preview }) => {
	
	const initialSiteState = {
		globalData,
		preview,
		pageTitle: null,
		cart: [],
		cartOpen: false,
		checklistData: {},
		popUp: false,
		globalData,
		preview
	}

	const [siteState, setSiteState] = useState(initialSiteState)
	const [init, setInit] = useState(false)

	useEffect(() => {
		if(init){
			localStorage.setItem('DOT_CART', JSON.stringify(siteState.cart), { expires: 5 })
		}
	}, [siteState, init])

	
	return(
		<SiteContext.Provider value={{
			siteState: siteState,
			setSiteState: setSiteState
		}}>
			{children}
		</SiteContext.Provider>
	)
}

SiteStore.propTypes = {
	children: PropTypes.node,
}

// hook to access siteState globally
export const useSiteState = () => {
	const { siteState, setSiteState } = useContext(SiteContext)
	return [siteState, setSiteState]
}

// hook to access siteState globally
export const useGlobalData = () => {
	const { siteState, setSiteState } = useContext(SiteContext)
	const globalData = siteState.globalData
	const setGlobalData = data => {
		setSiteState(prevState => ({
			...prevState,
			globalData: data
		}))
	}
	return [globalData, setGlobalData]
}